import Tracker from '@/util/tracker.js'

window.addEventListener('load', (ev) => {
  Tracker.track('page loaded', {
    url: window.location.toString(),
    clean_url: `${window.location.origin.toString()}${window.location.pathname.toString()}`,
  })
})

window.addEventListener('error', (ev) => {
  Tracker.track('page errored', {
    message: ev.message,
    filename: ev.filename,
    lineno: ev.lineno,
    colno: ev.colno,
    url: window.location.toString(),
  })
})
